// src/utils/communityToRegion.js

// Map communities to regions
const communityToRegion = {
    // צפון (North)
    'אבו בלאל': 'צפון',
    'אבו גווייעד (שבט)': 'צפון',
    'אבו גוש': 'צפון',
    'אבו געדה': 'צפון',
    'אבו דיס': 'צפון',
    'אבו חמרה': 'צפון',
    'אבו טוילה': 'צפון',
    'אבו סנאן': 'צפון',
    'אבו סריחאן (שבט)': 'צפון',
    'אבו עבדון (שבט)': 'צפון',
    'אבו עמאר (שבט)': 'צפון',
    'אבו עמרה (שבט)': 'צפון',
    'אבו קורינאת (שבט)': 'צפון',
    'אבו קרינאת (יישוב)': 'צפון',
    'אבו קש': 'צפון',
    'אבו רובייעה (שבט)': 'צפון',
    'אבו רוקייק (שבט)': 'צפון',
    'אבושחידם': 'צפון',
    'אבו תלול': 'דרום',
    'אבטין': 'צפון',
    'אבטליון': 'צפון',
    'אביאל': 'צפון',
    'אביבים': 'צפון',
    'אביגדור': 'מרכז',
    'אביחיל': 'מרכז',
    'אביטל': 'צפון',
    'אביעזר': 'צפון',
    'אבירים': 'צפון',
    'אבן יהודה': 'מרכז',
    'אבן מנחם': 'צפון',
    'אבן ספיר': 'צפון',
    'אבן שמואל': 'צפון',
    'אבני איתן': 'צפון',
    'אבני חפץ': 'צפון',
    'אבנת': 'צפון',
    'אבשלום': 'מרכז',
    'אבתאן': 'מרכז',
    'אדורה': 'צפון',
    'אדירים': 'צפון',
    'אדמית': 'צפון',
    'אדנה': 'מרכז',
    'אדרת': 'מרכז',
    'אהלו': 'דרום',
    'אודים': 'מרכז',
    'אודלה': 'מרכז',
    'אודם': 'צפון',
    'אוהד': 'מרכז',
    'אום אל-פחם': 'צפון',
    'אום אל-קוטוף': 'מרכז',
    'אום אל תות': 'מרכז',
    'אום בטין': 'צפון',
    'אומן': 'מרכז',
    'אומץ': 'צפון',
    'אופקים': 'דרום',
    'אוצרין': 'דרום',
    'אור הגנוז': 'צפון',
    'אור הנר': 'דרום',
    'אור יהודה': 'מרכז',
    'אור עקיבא': 'מרכז',
    'אורה': 'מרכז',
    'אורות': 'מרכז',
    'אורטל': 'מרכז',
    'אורים': 'דרום',
    'אורנים': 'מרכז',
    'אורנית': 'מרכז',
    'אושה': 'צפון',
    'אזור': 'מרכז',
    'אחווה': 'דרום',
    'אחוזם': 'דרום',
    'אחוזת ברק': 'מרכז',
    'אחיהוד': 'צפון',
    'אחיטוב': 'מרכז',
    'אחיסמך': 'מרכז',
    'אחיעזר': 'מרכז',
    'אטרש (שבט)': 'צפון',
    'איבים': 'צפון',
    'אייל': 'מרכז',
    'איילת השחר': 'צפון',
    'אילון': 'צפון',
    'אילות': 'דרום',
    'אילניה': 'צפון',
    'אילת': 'דרום',
    'איתמר': 'שומרון',
    'איתן': 'צפון',
    'איתנים': 'צפון',
    'אכסאל': 'צפון',
    'אכתאבא': 'צפון',
    'אל-עזי': 'צפון',
    'אל-עריאן': 'צפון',
    'אל-רום': 'צפון',
    'אל בירה': 'מרכז',
    'אל סייד': 'צפון',
    'אל עריש': 'מרכז',
    'אלומה': 'צפון',
    'אלומות': 'צפון',
    'אלון הגליל': 'צפון',
    'אלון מורה': 'שומרון',
    'אלון שבות': 'שומרון',
    'אלוני אבא': 'צפון',
    'אלוני הבשן': 'צפון',
    'אלוני יצחק': 'צפון',
    'אלונים': 'צפון',
    'אלי-עד': 'צפון',
    'אלי סיני': 'דרום',
    'אליכין': 'מרכז',
    'אליפז': 'דרום',
    'אליפלט': 'צפון',
    'אליקים': 'צפון',
    'אלישיב': 'מרכז',
    'אלישמע': 'מרכז',
    'אלמגור': 'צפון',
    'אלמוג': 'צפון',
    'אלעד': 'מרכז',
    'אלעזר': 'שומרון',
    'אלפי מנשה': 'שומרון',
    'אלקוש': 'צפון',
    'אלקנה': 'שומרון',
    'אם א טלע': 'צפון',
    'אם אל גנם': 'מרכז',
    'אם חשיבה': 'מרכז',
    'אם סלמונה': 'דרום',
    'אם עלאס': 'דרום',
    'אם עסלה': 'דרום',
    'אם צפא': 'צפון',
    'אם קוצרי': 'מרכז',
    'אמאתין': 'מרכז',
    'אמונים': 'דרום',
    'אמירים': 'צפון',
    'אמנון': 'מרכז',
    'אמציה': 'מרכז',
    'אמריש': 'מרכז',
    'אניעם': 'צפון',
    'אסד (שבט)': 'צפון',
    'אסכאכא': 'צפון',
    'אספר': 'מרכז',
    'אעבלין': 'צפון',
    'אעצם (שבט)': 'צפון',
    'אפיניש (שבט)': 'צפון',
    'אפיק': 'צפון',
    'אפיקים': 'צפון',
    'אפעל בית אב': 'מרכז',
    'אפעל מרכז ס': 'מרכז',
    'אפק': 'צפון',
    'אפרתה': 'מרכז',
    'ארבל': 'צפון',
    'ארגמן': 'מרכז',
    'ארז': 'דרום',
    'ארטאס': 'מרכז',
    'אריאל': 'שומרון',
    'ארסוף': 'מרכז',
    'ארתאח': 'שומרון',
    'אשבול': 'דרום',
    'אשבל': 'דרום',
    'אשדוד': 'שפלה',
    'אשדות יעקב (איחוד)': 'צפון',
    'אשדות יעקב (מאוחד)': 'צפון',
    'אשחר': 'צפון',
    'אשכולות': 'דרום',
    'אשל הנשיא': 'דרום',
    'אשלים': 'דרום',
    'אשקלון': 'דרום',
    'אשרת': 'מרכז',
    'אשתאול': 'מרכז',
    'אתגר': 'מרכז',
    'אתר מצדה': 'דרום',
    // Page 2 continues here
    'באגה': 'מרכז',
    'באקה': 'מרכז',
    'באקה אל-גרביה': 'מרכז',
    'באקה אל שרק': 'מרכז',
    'באר אורה': 'דרום',
    'באר גנים': 'מרכז',
    'באר טוביה': 'מרכז',
    'באר יעקב': 'מרכז',
    'באר מילכה': 'דרום',
    'באר שבע': 'דרום',
    'בארות יצחק': 'מרכז',
    'בארותיים': 'דרום',
    'בארי': 'דרום',
    'בגדד': 'מרכז',
    'בדו': 'מרכז',
    'בדולח': 'מרכז',
    'בדיא': 'מרכז',
    'בדרס': 'דרום',
    'בוגה': 'מרכז',
    'בוסתן הגליל': 'צפון',
    'בועיינה-נוגידאת': 'צפון',
    'בוקעאתא': 'צפון',
    'בורגתה': 'מרכז',
    'בורהאם': 'צפון',
    'בורין': 'צפון',
    'בורקה': 'צפון',
    'בזאריה': 'מרכז',
    'בחן': 'מרכז',
    'בטחה': 'מרכז',
    'ביאדה': 'צפון',
    'בידא': 'מרכז',
    'ביוכי': 'צפון',
    'ביצרון': 'דרום',
    'ביר א נצב': 'צפון',
    'ביר א סכה': 'צפון',
    'ביר אבו סוי': 'צפון',
    'ביר אבו צוי': 'צפון',
    'ביר אל-מכסור': 'צפון',
    'ביר אל חסי': 'צפון',
    'ביר אל מלחה': 'צפון',
    'ביר אל מעין': 'צפון',
    'ביר אל עבד': 'צפון',
    'ביר אלמיין': 'צפון',
    'ביר הדאג': 'צפון',
    'ביר הליל': 'צפון',
    'ביר זית': 'צפון',
    'ביר חסנה': 'צפון',
    'ביר לחפאן': 'צפון',
    'ביר מלאקי': 'צפון',
    'ביר מער': 'צפון',
    'ביר נבאלא': 'צפון',
    'ביר נצב': 'צפון',
    'ביר סדרי': 'צפון',
    'בירשיח חמי': 'צפון',
    'ביר תל': 'צפון',
    'בירה חברון': 'דרום',
    'ביריה': 'מרכז',
    'בית אולא': 'מרכז',
    'בית אורן': 'מרכז',
    'בית איבא': 'מרכז',
    'בית אכסא': 'מרכז',
    'בית אל': 'מרכז',
    'בית אל ב': 'מרכז',
    'בית אללו': 'מרכז',
    'בית אלעזרי': 'מרכז',
    'בית אלפא': 'מרכז',
    'בית אמין': 'מרכז',
    'בית אמר': 'מרכז',
    'בית אמרין': 'מרכז',
    'בית אענאן': 'מרכז',
    'בית ארוש פו': 'מרכז',
    'בית ארוש תח': 'מרכז',
    'בית אריה': 'מרכז',
    'בית ברל': 'מרכז',
    'בית גוברין': 'מרכז',
    'בית גמאל': 'מרכז',
    'בית גמליאל': 'מרכז',
    'בית גן': 'מרכז',
    'בית דגן': 'מרכז',
    'בית דקו': 'מרכז',
    'בית הגדי': 'מרכז',
    'בית הלוי': 'מרכז',
    'בית הלל': 'מרכז',
    'בית העמק': 'מרכז',
    'בית הערבה': 'מרכז',
    'בית השיטה': 'מרכז',
    'בית וזן': 'מרכז',
    'בית זיד': 'מרכז',
    'בית זית': 'מרכז',
    'בית זר': 'מרכז',
    'בית חורון': 'מרכז',
    'בית חירות': 'מרכז',
    'בית חלקיה': 'מרכז',
    'בית חנון': 'צפון',
    'בית חנינא': 'צפון',
    'בית חנן': 'דרום',
    'בית חנניה': 'צפון',
    'בית חשמונאי': 'מרכז',
    'בית יהושע': 'מרכז',
    'בית יוסף': 'מרכז',
    'בית ינאי': 'מרכז',
    'ביתיצחק-שער חפר': 'מרכז',
    'בית כאחל גמ': 'מרכז',
    'בית לאהיה': 'דרום',
    'בית לחם': 'מרכז',
    'בית לחם הגלילית': 'צפון',
    'בית ליד': 'מרכז',
    'בית לקיא': 'מרכז',
    'בית מאיר': 'מרכז',
    'בית מחסיר': 'מרכז',
    'בית מרסם': 'מרכז',
    'בית נחמיה': 'מרכז',
    'בית ניר': 'מרכז',
    'בית נקופה': 'מרכז',
    'בית סאחור': 'דרום',
    'בית סוריכ': 'מרכז',
    'בית סירא': 'מרכז',
    'בית עוא': 'מרכז',
    'בית עובד': 'מרכז',
    'בית עוזיאל': 'מרכז',
    'בית עור אל': 'מרכז',
    'בית עזרא': 'מרכז',
    'בית עמרא': 'מרכז',
    'ביתעריף': 'מרכז',
    'בית פוריכ': 'מרכז',
    'בית צבי': 'מרכז',
    'בית קאד דרו': 'מרכז',
    'בית קאד צפו': 'מרכז',
    'בית קמה': 'מרכז',
    'בית קשת': 'מרכז',
    'בית רבן': 'מרכז',
    'בית רימא': 'מרכז',
    'בית רימון': 'מרכז',
    'בית שאן': 'צפון',
    'בית שמש': 'מרכז',
    'בית שערים': 'מרכז',
    'בית שקמה': 'מרכז',
    'ביתא א תחתא': 'מרכז',
    'ביתא פוקא': 'מרכז',
    'ביתוניא': 'מרכז',
    'ביתין': 'מרכז',
    'ביתן אהרן': 'מרכז',
    'ביתר עילית': 'מרכז',
    'בכורה': 'דרום',
    'בלאטה': 'צפון',
    'בלעא': 'צפון',
    'בלעין': 'צפון',
    'בלפוריה': 'מרכז',
    'בןזכאי': 'מרכז',
    'בן עמי': 'מרכז',
    'בן שמן (כפר נוער)': 'מרכז',
    'בן שמן (מושב)': 'מרכז',
    'בני ברק': 'מרכז',
    'בני דקלים': 'דרום',
    'בני דרום': 'דרום',
    'בני דרור': 'מרכז',
    'בני יהודה': 'מרכז',
    'בני נעים': 'דרום',
    'בני נצרים': 'דרום',
    'בני סוהילה': 'דרום',
    'בני עטרות': 'מרכז',
    'בני עי"ש': 'מרכז',
    'בני עצמון': 'מרכז',
    'בני ציון': 'מרכז',
    'בני ראם': 'דרום',
    'בניה': 'מרכז',
    'בנימינה-גבעת עדה': 'מרכז',
    'בסמ"ה': 'מרכז',
    'בסמת טבעון': 'צפון',
    'בענה': 'צפון',
    'בצרה': 'מרכז',
    'בצת': 'מרכז',
    'בקוע': 'מרכז',
    'בקעות': 'מרכז',
    'בר גיורא': 'מרכז',
    'בר יוחאי': 'צפון',
    'ברג"': 'מרכז',
    'ברדלא': 'מרכז',
    'ברוקין': 'מרכז',
    'ברור חיל': 'מרכז',
    'ברוש': 'מרכז',
    'ברטעה': 'צפון',
    'בריג': 'מרכז',
    'ברכה': 'מרכז',
    'ברכיה': 'מרכז',
    'ברעם': 'צפון',
    'ברק': 'מרכז',
    'ברקא': 'מרכז',
    'ברקאי': 'מרכז',
    'ברקין': 'מרכז',
    'ברקן': 'מרכז',
    'ברקת': 'מרכז',
    'בת הדר': 'מרכז',
    'בת חן': 'מרכז',
    'בת חפר': 'מרכז',
    'בת חצור': 'מרכז',
    'בת ים': 'מרכז',
    'בת עין': 'מרכז',
    'בת שלמה': 'מרכז',
    'בתיר': 'מרכז',
    'גאולי תימן': 'מרכז',
    'גאולים': 'מרכז',
    'גאליה': 'מרכז',
    'גבאליה': 'מרכז',
    'גבאליה העיר': 'מרכז',
    'גבולות': 'מרכז',
    'גבים': 'מרכז',
    'גבל אל ערף': 'שומרון',
    'גבל הלאל': 'שומרון',
    'גבל חמיר': 'שומרון',
    'גבל מגארה': 'שומרון',
    'גבע': 'מרכז',
    'גבע בנימין': 'שומרון',
    'גבע כרמל': 'צפון',
    'גבעולים': 'מרכז',
    'גבעון החדשה': 'שומרון',
    'גבעות בר': 'מרכז',
    'גבעת אבני': 'מרכז',
    'גבעת אלה': 'מרכז',
    'גבעת ברנר': 'מרכז',
    'גבעת השלושה': 'מרכז',
    'גבעת זאב': 'מרכז',
    'גבעת ח"ן': 'מרכז',
    'גבעת חיים (איחוד)': 'מרכז',
    'גבעת חיים (מאוחד)': 'מרכז',
    'גבעת יואב': 'צפון',
    'גבעת יערים': 'מרכז',
    'גבעת ישעיהו': 'מרכז',
    'גבעת כ"ח': 'מרכז',
    'גבעת ניל"י': 'מרכז',
    'גבעת עדה': 'מרכז',
    'גבעת עוז': 'מרכז',
    'גבעת שמואל': 'מרכז',
    'גבעת שמש': 'מרכז',
    'גבעת שפירא': 'מרכז',
    'גבעתי': 'מרכז',
    'גבעתיים': 'מרכז',
    'גברעם': 'צפון',
    'גבת': 'מרכז',
    'גדות': 'מרכז',
    'גדיד': 'דרום',
    'גדידה': 'מרכז',
    'גדיידה-מכר': 'צפון',
    'גדיש': 'מרכז',
    'גדעונה': 'מרכז',
    'גדרה': 'מרכז',
    'גולס': 'צפון',
    'גונן': 'צפון',
    'גורן': 'צפון',
    'גורנות הגליל': 'צפון',
    'גזית': 'מרכז',
    'גזר': 'מרכז',
    'גיאה': 'מרכז',
    'גיבתון': 'מרכז',
    'גיזו': 'מרכז',
    'גילון': 'צפון',
    'גילת': 'מרכז',
    'גינוסר': 'מרכז',
    'גיניגר': 'מרכז',
    'גינתון': 'מרכז',
    'גיתה': 'מרכז',
    'גיתית': 'מרכז',
    'גלאון': 'מרכז',
    'גלגוליה': 'מרכז',
    'גלגל': 'מרכז',
    'גליל ים': 'מרכז',
    'גלעד(אבן יצחק)': 'מרכז',
    'גמזו': 'מרכז',
    'גן אור': 'מרכז',
    'גן הדרום': 'דרום',
    'גן השומרון': 'שומרון',
    'גן חיים': 'מרכז',
    'גן יאשיה': 'מרכז',
    'גן יבנה': 'מרכז',
    'גן נר': 'מרכז',
    'גן שורק': 'מרכז',
    'גן שלמה': 'מרכז',
    'גן שמואל': 'מרכז',
    'גנאביב (שבט)': 'צפון',
    'גנות': 'מרכז',
    'גנות הדר': 'מרכז',
    'גני הדר': 'מרכז',
    'גני טל': 'מרכז',
    'גני יהודה': 'מרכז',
    'גני יוחנן': 'מרכז',
    'גני מודיעין': 'מרכז',
    'גני עם': 'מרכז',
    'גני תקווה': 'מרכז',
    'גנים': 'מרכז',
    'גסר א-זרקא': 'צפון',
    'געש': 'מרכז',
    'גת': 'מרכז',
    'גת (קיבוץ)': 'מרכז',
    'גת בגליל': 'צפון',
    'גת רימון': 'מרכז',
    'דאהריה': 'דרום',
    'דאלית אל-כרמל': 'צפון',
    'דבורה': 'מרכז',
    'דבוריה': 'מרכז',
    'דבירה': 'מרכז',
    'דברת': 'מרכז',
    'דגניה א': 'צפון',
    'דגניה ב': 'צפון',
    'דהב': 'דרום',
    'דהישה': 'דרום',
    'דהר חלת אל': 'דרום',
    'דוב"ב': 'מרכז',
    'דוגית': 'מרכז',
    'דולב': 'מרכז',
    'דומא חברון': 'דרום',
    'דומא שכם': 'שומרון',
    'דור': 'מרכז',
    'דורא': 'מרכז',
    'דוראאל קרע': 'מרכז',
    'דורות': 'מרכז',
    'דחי': 'דרום',
    'דחית בריד': 'מרכז',
    'דיוכ': 'מרכז',
    'דייר חנא': 'דרום',
    'דייר ראפאת': 'מרכז',
    'דימונה': 'דרום',
    'דיר א שמס': 'צפון',
    'דיר אבו דעי': 'צפון',
    'דיר אבו משע': 'דרום',
    'דיר אבזיע': 'דרום',
    'דיר אל-אסד': 'צפון',
    'דיר אל בלח': 'דרום',
    'דיר אל בלח': 'דרום',
    'דיר אל גצון': 'צפון',
    'דיר אל חטב': 'דרום',
    'דיר אל סודא': 'מרכז',
    'דיר אל עסל': 'מרכז',
    'דיר אל עסל': 'מרכז',
    'דיר אסתיא': 'מרכז',
    'דיר בלוט': 'דרום',
    'דיר גזאלה': 'דרום',
    'דיר גסאנה': 'דרום',
    'דיר גריר': 'דרום',
    'דיר דבואן': 'דרום',
    'דיר נזאם': 'מרכז',
    'דיר עמאר': 'דרום',
    'דיר קדיס': 'דרום',
    'דיר רזאח': 'דרום',
    'דיר שרף': 'דרום',
    'דישון': 'צפון',
    'דליה': 'מרכז',
    'דלתון': 'צפון',
    'דמיידה': 'מרכז',
    'דן': 'מרכז',
    'דנאבה': 'מרכז',
    'דפידף': 'מרכז',
    'דפנה': 'מרכז',
    'דקל': 'מרכז',
    'דרב אל מליח': 'מרכז',
    'דריגאת': 'צפון',
    'האון': 'מרכז',
    'האשמיה': 'צפון',
    'הבונים': 'מרכז',
    'הגושרים': 'צפון',
    'הדר עם': 'מרכז',
    'הוד השרון': 'מרכז',
    'הודיה': 'מרכז',
    'הודיות': 'מרכז',
    'הוואשלה (שבט)': 'דרום',
    'הוזייל (שבט)': 'דרום',
    'הושעיה': 'מרכז',
    'הזורע': 'מרכז',
    'הזורעים': 'מרכז',
    'החותרים': 'מרכז',
    'היוגב': 'מרכז',
    'הילה': 'מרכז',
    'המעפיל': 'מרכז',
    'הסוללים': 'מרכז',
    'העוגן': 'מרכז',
    'הר אדר': 'מרכז',
    'הר גילה': 'מרכז',
    'הר עמשא': 'מרכז',
    'הראל': 'מרכז',
    'הרדוף': 'צפון',
    'הרצליה': 'מרכז',
    'הררית': 'צפון',
    'ואדי אל אחמ': 'דרום',
    'ואדי אל ביד': 'מרכז',
    'ואדי אל וער': 'דרום',
    'ואדי אל ערא': 'דרום',
    'ואדי אל ערי': 'דרום',
    'ואדי אסלה': 'דרום',
    'ואדי ברקין': 'מרכז',
    'ואדי גרנדל': 'דרום',
    'ואדי חמר': 'מרכז',
    'ואדי מבעוק': 'דרום',
    'ואדי מחאש': 'מרכז',
    'ואדי נצב': 'מרכז',
    'ואדי נצריין': 'מרכז',
    'ואדי סעאל': 'מרכז',
    'ואדי קיד': 'מרכז',
    'ואדי קריה': 'מרכז',
    'ואדי רהבה': 'דרום',
    'ואדי רחבה': 'דרום',
    'ואדי רינה': 'דרום',
    'ואדי תמן': 'דרום',
    'ואדי תר': 'דרום',
    'ואדיאל עמר': 'דרום',
    'ואסט נויבע': 'דרום',
    'ורד יריחו': 'מרכז',
    'ורדון': 'מרכז',
    'זאויה ג\'נין': 'שומרון',
    'זאויה טול כ': 'שומרון',
    'זבאבדה': 'שומרון',
    'זבארגה (שבט)': 'דרום',
    'זבדה': 'מרכז',
    'זבדיאל': 'מרכז',
    'זבובה': 'מרכז',
    'זבידאת': 'שומרון',
    'זואתה': 'מרכז',
    'זוהר': 'מרכז',
    'זוואידה': 'מרכז',
    'זיף': 'מרכז',
    'זיקים': 'דרום',
    'זיתא': 'שומרון',
    'זיתא ג\'מעין': 'שומרון',
    'זיתונה': 'מרכז',
    'זיתן': 'מרכז',
    'זכרון יעקב': 'מרכז',
    'זכריה': 'מרכז',
    'זלפה': 'מרכז',
    'זמר': 'מרכז',
    'זמרת': 'מרכז',
    'זנוח': 'מרכז',
    'זעתרה': 'מרכז',
    'זרועה': 'מרכז',
    'זרזיר': 'מרכז',
    'זרחיה': 'מרכז',
    'חאן יונס': 'דרום',
    'חאן יונס הע': 'דרום',
    'חאראס': 'מרכז',
    'חארס': 'מרכז',
    'חבילה': 'מרכז',
    'חבלה': 'מרכז',
    'חבצלת השרון': 'מרכז',
    'חבר': 'מרכז',
    'חברון': 'דרום',
    'חגאגרה': 'מרכז',
    'חגה': 'מרכז',
    'חגור': 'מרכז',
    'חגי': 'מרכז',
    'חגילה': 'מרכז',
    'חגלה': 'מרכז',
    'חד-נס': 'מרכז',
    'חדב': 'מרכז',
    'חדיד': 'מרכז',
    'חדר': 'מרכז',
    'חדרה': 'מרכז',
    'חואלד': 'מרכז',
    'חואלד (שבט)': 'דרום',
    'חוארה': 'שומרון',
    'חוגייראת (דהרה)': 'דרום',
    'חולדה': 'מרכז',
    'חולון': 'מרכז',
    'חולית': 'מרכז',
    'חולתה': 'צפון',
    'חומש': 'שומרון',
    'חוסאן': 'דרום',
    'חוסן': 'מרכז',
    'חוסנייה': 'מרכז',
    'חופית': 'מרכז',
    'חוקוק': 'מרכז',
    'חורה': 'דרום',
    'חורסה': 'מרכז',
    'חורפיש': 'צפון',
    'חורשים': 'מרכז',
    'חות שלם': 'דרום',
    'חזאעה': 'דרום',
    'חזון': 'מרכז',
    'חזמא': 'דרום',
    'חיבת ציון': 'מרכז',
    'חיננית': 'מרכז',
    'חיפה': 'צפון',
    'חירות': 'מרכז',
    'חלוץ': 'מרכז',
    'חלחול': 'דרום',
    'חלמיש': 'מרכז',
    'חלף': 'מרכז',
    'חלץ': 'מרכז',
    'חלת אל פולה': 'מרכז',
    'חמאם': 'דרום',
    'חמד': 'דרום',
    'חמדיה': 'דרום',
    'חמדת': 'מרכז',
    'חמרה': 'דרום',
    'חניאל': 'מרכז',
    'חניתה': 'צפון',
    'חנתון': 'מרכז',
    'חסכה': 'מרכז',
    'חספין': 'צפון',
    'חפץ חיים': 'מרכז',
    'חפצי-בה': 'מרכז',
    'חצב': 'מרכז',
    'חצבה': 'מרכז',
    'חצור-אשדוד': 'דרום',
    'חצור הגלילית': 'צפון',
    'חצר בארותיים': 'מרכז',
    'חצרות חולדה': 'מרכז',
    'חצרות חפר': 'מרכז',
    'חצרות יסף': 'מרכז',
    'חצרות כ"ח': 'מרכז',
    'חצרים': 'מרכז',
    'חרב לאת': 'דרום',
    'חרבת אבו חמ': 'דרום',
    'חרבת אבו נג': 'דרום',
    'חרבת אבו סל': 'דרום',
    'חרבת אבו פל': 'דרום',
    'חרבת אום אל': 'דרום',
    'חרבת אל נג': 'מרכז',
    'חרבת אלאקר': 'מרכז',
    'חרבת אל אשק': 'מרכז',
    'חרבת אל דיר': 'מרכז',
    'חרבת אל דלב': 'מרכז',
    'חרבת אל הגר': 'מרכז',
    'חרבת אל חדב': 'מרכז',
    'חרבת אל חפי': 'מרכז',
    'חרבת אל חרז': 'מרכז',
    'חרבת אל טבק': 'מרכז',
    'חרבת אל כום': 'מרכז',
    'חרבת אל לוז': 'מרכז',
    'חרבת אל מגו': 'מרכז',
    'חרבת אל מגנ': 'מרכז',
    'חרבת אל מדו': 'מרכז',
    'חרבת אל מטל': 'דרום',
    'חרבת אל מנש': 'דרום',
    'חרבת אל מעצ': 'דרום',
    'חרבת אל מרג': 'דרום',
    'חרבת אל נבי': 'דרום',
    'חרבת אל סכה': 'דרום',
    'חרבת אל עדס': 'דרום',
    'חרבת אל עוג': 'דרום',
    'חרבת אל עלק': 'דרום',
    'חרבת אל עקר': 'דרום',
    'חרבת אל ערו': 'דרום',
    'חרבת אל צרי': 'דרום',
    'חרבת אל קצו': 'דרום',
    'חרבת אל שיח': 'דרום',
    'חרבת בית חס': 'דרום',
    'חרבת בית ענ': 'דרום',
    'חרבת בית תע': 'דרום',
    'חרבת בריזעה': 'דרום',
    'חרבת גאדור': 'דרום',
    'חרבת גהזם': 'דרום',
    'חרבת גיב א': 'דרום',
    'חרבת גראד': 'דרום',
    'חרבת דיר צא': 'דרום',
    'חרבת ואדי א': 'דרום',
    'חרבת ודח': 'דרום',
    'חרבת זאהר': 'דרום',
    'חרבתזיתא': 'דרום',
    'חרבת חדיר': 'דרום',
    'חרבת טויל': 'דרום',
    'חרבת כזון': 'דרום',
    'חרבת כנעאן': 'דרום',
    'חרבת כרמה': 'דרום',
    'חרבת מורק': 'דרום',
    'חרבת מעין': 'דרום',
    'חרבת נבי צא': 'דרום',
    'חרבת סמיא': 'דרום',
    'חרבת עאבא': 'דרום',
    'חרבת עוד': 'דרום',
    'חרבת עילת ע': 'דרום',
    'חרבת עסלה': 'דרום',
    'חרבת פקיקס': 'דרום',
    'חרבת צאפא': 'דרום',
    'חרבת ציר': 'דרום',
    'חרבת קיס': 'דרום',
    'חרבת ראס אל': 'דרום',
    'חרבת ראס עט': 'דרום',
    'חרבת רקעה': 'דרום',
    'חרבת שויכה': 'דרום',
    'חרבתא': 'דרום',
    'חרבתא אל מצ': 'דרום',
    'חרובה כולל': 'דרום',
    'חרוצים': 'דרום',
    'חריש-קציר': 'מרכז',
    'חרמלה': 'דרום',
    'חרמש': 'מרכז',
    'חרסה': 'מרכז',
    'חרשים': 'מרכז',
    'חשמונאים': 'מרכז',
    'טבעון': 'מרכז',
    'טבריה': 'צפון',
    'טואני': 'מרכז',
    'טובא-זנגריה': 'צפון',
    'טובאס': 'מרכז',
    'טויל אל אמי': 'מרכז',
    'טול כרם': 'מרכז',
    'טורה אל גרב': 'מרכז',
    'טורעאן': 'מרכז',
    'טיבה ג\'נין': 'שומרון',
    'טיבה רמאללה': 'מרכז',
    'טייבה': 'מרכז',
    'טייבה (בעמק)': 'מרכז',
    'טירה': 'מרכז',
    'טירת יהודה': 'מרכז',
    'טירת כרמל': 'צפון',
    'טירת צבי': 'מרכז',
    'טל-אל': 'מרכז',
    'טל שחר': 'מרכז',
    'טלוזה': 'מרכז',
    'טללים': 'מרכז',
    'טלמון': 'מרכז',
    'טמון': 'מרכז',
    'טמרה': 'מרכז',
    'טמרה (יזרעאל)': 'מרכז',
    'טנא': 'מרכז',
    'טפחות': 'מרכז',
    'טראמה': 'דרום',
    'יאטה': 'דרום',
    'יאמון': 'דרום',
    'יאנוח': 'דרום',
    'יאנוח-גת': 'דרום',
    'יאנון': 'דרום',
    'יאסוף': 'דרום',
    'יאציד': 'דרום',
    'יבול': 'מרכז',
    'יבנאל': 'מרכז',
    'יבנה': 'מרכז',
    'יברוד': 'מרכז',
    'יגור': 'מרכז',
    'יגל': 'מרכז',
    'יד בנימין': 'מרכז',
    'יד השמונה': 'מרכז',
    'יד חנה': 'מרכז',
    'יד מרדכי': 'מרכז',
    'יד נתן': 'מרכז',
    'יד רמב"ם': 'מרכז',
    'ידידה': 'מרכז',
    'יהוד-מונוסון': 'מרכז',
    'יהל': 'מרכז',
    'יובל': 'מרכז',
    'יובלים': 'מרכז',
    'יודפת': 'מרכז',
    'יונתן': 'מרכז',
    'יושיביה': 'מרכז',
    'יזרעאל': 'מרכז',
    'יזרעם': 'מרכז',
    'יחיעם': 'מרכז',
    'יטבתה': 'דרום',
    'ייט"ב': 'מרכז',
    'יכיני': 'מרכז',
    'ימא': 'מרכז',
    'ינוב': 'מרכז',
    'ינון': 'מרכז',
    'יסוד המעלה': 'מרכז',
    'יסודות': 'מרכז',
    'יסעור': 'מרכז',
    'יעבד': 'מרכז',
    'יעד': 'מרכז',
    'יעל': 'מרכז',
    'יעף': 'מרכז',
    'יערה': 'מרכז',
    'יפיע': 'מרכז',
    'יפית': 'מרכז',
    'יפעת': 'מרכז',
    'יפתח': 'מרכז',
    'יצהר': 'מרכז',
    'יציץ': 'מרכז',
    'יקום': 'מרכז',
    'יקיר': 'מרכז',
    'יקנעם (מושבה)': 'מרכז',
    'יקנעם עילית': 'מרכז',
    'יראון': 'צפון',
    'ירדנה': 'מרכז',
    'ירוחם': 'דרום',
    'ירושלים': 'מרכז',
    'ירחיב': 'מרכז',
    'יריחו': 'מרכז',
    'ירכא': 'דרום',
    'ירקונה': 'מרכז',
    'ישע': 'מרכז',
    'ישעי': 'מרכז',
    'ישרש': 'מרכז',
    'יתד': 'מרכז',
    'יתיר': 'מרכז',
    'יתמא': 'מרכז',
    'כאבול': 'צפון',
    'כאוכב אבו אל-היגא': 'מרכז',
    'כברי': 'מרכז',
    'כדורי': 'מרכז',
    'כדים': 'מרכז',
    'כדיתה': 'מרכז',
    'כובר': 'מרכז',
    'כוכב השחר': 'מרכז',
    'כוכב יאיר': 'מרכז',
    'כוכב יעקב': 'מרכז',
    'כוכב מיכאל': 'מרכז',
    'כונתילא': 'מרכז',
    'כור': 'צפון',
    'כורזים': 'צפון',
    'כזיה אל רטר': 'מרכז',
    'כחל': 'מרכז',
    'כחלה': 'מרכז',
    'כיסופים': 'מרכז',
    'כישור': 'מרכז',
    'כליל': 'צפון',
    'כלנית': 'מרכז',
    'כמאנה': 'מרכז',
    'כמהין': 'מרכז',
    'כמון': 'מרכז',
    'כנות': 'מרכז',
    'כנף': 'מרכז',
    'כנרת (מושבה)': 'צפון',
    'כנרת (קבוצה)': 'צפון',
    'כסיפה': 'דרום',
    'כסלון': 'מרכז',
    'כסרא': 'צפון',
    'כסרא-סמיע': 'צפון',
    'כעביה-טבאש-חגאגרה': 'מרכז',
    'כעביה שבט': 'מרכז',
    'כפיר': 'מרכז',
    'כפירת': 'מרכז',
    'כפל חארת': 'מרכז',
    'כפר אביב': 'מרכז',
    'כפר אדומים': 'מרכז',
    'כפר אוריה': 'מרכז',
    'כפר אזר': 'מרכז',
    'כפר אחים': 'מרכז',
    'כפר אל דיכ': 'מרכז',
    'כפר אל לבד': 'מרכז',
    'כפר ביאליק': 'מרכז',
    'כפר ביל"ו': 'מרכז',
    'כפר בלום': 'צפון',
    'כפר בן נון': 'מרכז',
    'כפר ברא': 'מרכז',
    'כפרברוך': 'מרכז',
    'כפר גדעון': 'מרכז',
    'כפר גלים': 'מרכז',
    'כפר גליקסון': 'מרכז',
    'כפר גלעדי': 'צפון',
    'כפר גמאל': 'מרכז',
    'כפר דאן': 'מרכז',
    'כפר דניאל': 'מרכז',
    'כפר דרום': 'מרכז',
    'כפר האורנים': 'מרכז',
    'כפר החורש': 'מרכז',
    'כפר המכבי': 'מרכז',
    'כפר הנגיד': 'מרכז',
    'כפר הנוער הדתי': 'מרכז',
    'כפר הנשיא': 'מרכז',
    'כפר הס': 'מרכז',
    'כפר הרא"ה': 'מרכז',
    'כפר הרי"ף': 'מרכז',
    'כפר ויתקין': 'מרכז',
    'כפר ורבורג': 'מרכז',
    'כפר ורדים': 'צפון',
    'כפר זוהרים': 'מרכז',
    'כפר זיבאד': 'מרכז',
    'כפרזיתים': 'מרכז',
    'כפר חב"ד': 'מרכז',
    'כפר חושן': 'מרכז',
    'כפר חיטים': 'מרכז',
    'כפר חיים': 'מרכז',
    'כפר חנניה': 'מרכז',
    'כפר חסידים א': 'מרכז',
    'כפר חסידים ב': 'מרכז',
    'כפר חרוב': 'מרכז',
    'כפר טרומן': 'מרכז',
    'כפר יאסיף': 'צפון',
    'כפר ידידיה': 'מרכז',
    'כפר יהושע': 'מרכז',
    'כפר יונה': 'מרכז',
    'כפר יחזקאל': 'מרכז',
    'כפר יעבץ': 'מרכז',
    'כפר כמא': 'מרכז',
    'כפר כנא': 'צפון',
    'כפר לאקף': 'מרכז',
    'כפר מאלכ': 'מרכז',
    'כפר מונש': 'מרכז',
    'כפר מימון': 'מרכז',
    'כפר מל"ל': 'מרכז',
    'כפר מנדא': 'צפון',
    'כפר מנחם': 'מרכז',
    'כפר מסריק': 'מרכז',
    'כפר מצר': 'מרכז',
    'כפר מרדכי': 'מרכז',
    'כפר נטר': 'מרכז',
    'כפר נעמה': 'מרכז',
    'כפר סאלד': 'צפון',
    'כפר סבא': 'מרכז',
    'כפר סילבר': 'מרכז',
    'כפר סירקין': 'מרכז',
    'כפר סמיע': 'צפון',
    'כפר עבודה': 'מרכז',
    'כפר עבוש': 'מרכז',
    'כפר עזה': 'דרום',
    'כפר עזיז': 'מרכז',
    'כפר עין': 'מרכז',
    'כפר עציון': 'מרכז',
    'כפר עקב': 'מרכז',
    'כפר פינס': 'מרכז',
    'כפר צור': 'מרכז',
    'כפר קאסם': 'מרכז',
    'כפר קדום': 'מרכז',
    'כפר קוד': 'מרכז',
    'כפר קיש': 'מרכז',
    'כפר קליל': 'מרכז',
    'כפר קרע': 'מרכז',
    'כפר ראעי': 'מרכז',
    'כפר ראש הנקרה': 'צפון',
    'כפר רוזנואלד (זרעית)': 'מרכז',
    'כפר רופין': 'מרכז',
    'כפר רות': 'מרכז',
    'כפר רמאן': 'מרכז',
    'כפר שמאי': 'מרכז',
    'כפר שמואל': 'מרכז',
    'כפר שמריהו': 'מרכז',
    'כפר תבור': 'מרכז',
    'כפר תלת': 'מרכז',
    'כפר תפוח': 'מרכז',
    'כרזה': 'מרכז',
    'כרי דשא': 'מרכז',
    'כרכום': 'מרכז',
    'כרם אבו מצל': 'מרכז',
    'כרם אבו שרי': 'מרכז',
    'כרם בן זמרה': 'מרכז',
    'כרם בן שמן': 'מרכז',
    'כרם יבנה (ישיבה)': 'מרכז',
    'כרם מהר"ל': 'מרכז',
    'כרם שלום': 'מרכז',
    'כרמייוסף': 'מרכז',
    'כרמי צור': 'מרכז',
    'כרמיאל': 'צפון',
    'כרמיה': 'מרכז',
    'כרמים': 'מרכז',
    'כרמל': 'צפון',
    'לבון': 'מרכז',
    'לביא': 'מרכז',
    'לבן': 'מרכז',
    'לבן שרקיה': 'מרכז',
    'לבנים': 'מרכז',
    'להב': 'מרכז',
    'להבות הבשן': 'צפון',
    'להבות חביבה': 'מרכז',
    'להבים': 'דרום',
    'לוד': 'מרכז',
    'לוזית': 'מרכז',
    'לוחמי הגיטאות': 'מרכז',
    'לוטם': 'מרכז',
    'לוטן': 'מרכז',
    'לימן': 'צפון',
    'לכיש': 'מרכז',
    'לפיד': 'מרכז',
    'לפידות': 'מרכז',
    'לקיה': 'דרום',
    'מאדמא': 'מרכז',
    'מאור': 'מרכז',
    'מאיר שפיה': 'מרכז',
    'מאסורה': 'דרום',
    'מאקורה': 'מרכז',
    'מבוא ביתר': 'מרכז',
    'מבוא דותן': 'מרכז',
    'מבואחורון': 'מרכז',
    'מבוא חמה': 'מרכז',
    'מבוא מודיעים': 'מרכז',
    'מבואות ים': 'מרכז',
    'מבועים': 'מרכז',
    'מבטחים': 'מרכז',
    'מבקיעים': 'מרכז',
    'מבשרת ציון': 'מרכז',
    'מגאזי': 'מרכז',
    'מגאר': 'דרום',
    'מגד': 'מרכז',
    'מגד אל-כרום': 'צפון',
    'מגדים': 'מרכז',
    'מגדל': 'מרכז',
    'מגדל בני פא': 'מרכז',
    'מגדל העמק': 'מרכז',
    'מגדל עוז': 'מרכז',
    'מגדל שמס': 'צפון',
    'מגדלים': 'מרכז',
    'מגידו': 'מרכז',
    'מגיר ג\'נין': 'שומרון',
    'מגיר רמאללה': 'מרכז',
    'מגל': 'מרכז',
    'מגן': 'מרכז',
    'מגן שאול': 'מרכז',
    'מגשימים': 'מרכז',
    'מדיה': 'מרכז',
    'מדפונה': 'מרכז',
    'מדרךעוז': 'מרכז',
    'מדרשת בן גוריון': 'דרום',
    'מדרשת רופין': 'מרכז',
    'מודיעין-מכבים-רעות': 'מרכז',
    'מודיעין עילית': 'מרכז',
    'מולדה': 'דרום',
    'מולדת': 'דרום',
    'מוצא עילית': 'מרכז',
    'מוצא תחתית': 'מרכז',
    'מוצמוץ': 'מרכז',
    'מוקייבלה': 'מרכז',
    'מורג': 'מרכז',
    'מורן': 'מרכז',
    'מורשת': 'מרכז',
    'מושב אליאב': 'מרכז',
    'מזאר': 'מרכז',
    'מזארע אל נו': 'מרכז',
    'מזור': 'מרכז',
    'מזכרת בתיה': 'מרכז',
    'מזרע': 'מרכז',
    'מזרעה': 'מרכז',
    'מזרעה אל קב': 'מרכז',
    'מזרעה אל שר': 'מרכז',
    'מחולה': 'מרכז',
    'מחים אל פאר': 'מרכז',
    'מחים קלנדיה': 'מרכז',
    'מחמאס': 'מרכז',
    'מחנה גבעת ח': 'מרכז',
    'מחנה הילה': 'מרכז',
    'מחנה טלי': 'מרכז',
    'מחנה יבור': 'מרכז',
    'מחנה יהודית': 'מרכז',
    'מחנה יוכבד': 'מרכז',
    'מחנה יפה': 'מרכז',
    'מחנה יתיר': 'מרכז',
    'מחנה מרים': 'מרכז',
    'מחנה עדי': 'מרכז',
    'מחנה תל נוף': 'מרכז',
    'מחניים': 'מרכז',
    'מחסיה': 'מרכז',
    'מחשיב': 'מרכז',
    'מטולה': 'צפון',
    'מטע': 'מרכז',
    'מי עמי': 'מרכז',
    'מידאן': 'מרכז',
    'מיטב': 'מרכז',
    'מייסר': 'מרכז',
    'מיצר': 'מרכז',
    'מירב': 'מרכז',
    'מירון': 'צפון',
    'מישר': 'מרכז',
    'מיתלה': 'מרכז',
    'מיתלון': 'מרכז',
    'מיתר': 'מרכז',
    'מכבים': 'מרכז',
    'מכורה': 'מרכז',
    'מכחול': 'מרכז',
    'מכמורת': 'מרכז',
    'מכמנים': 'מרכז',
    'מכסורה': 'מרכז',
    'מכר': 'מרכז',
    'מלאה': 'מרכז',
    'מלילות': 'מרכז',
    'מלכיה': 'צפון',
    'מלכישוע': 'מרכז',
    'מנבטח': 'מרכז',
    'מנוחה': 'מרכז',
    'מנוף': 'מרכז',
    'מנות': 'מרכז',
    'מנחמיה': 'מרכז',
    'מנרה': 'מרכז',
    'מנשית זבדה': 'מרכז',
    'מסד': 'מרכז',
    'מסדה': 'מרכז',
    'מסחה': 'מרכז',
    'מסילות': 'מרכז',
    'מסילת ציון': 'מרכז',
    'מסלול': 'מרכז',
    'מסליה': 'מרכז',
    'מסעדה': 'מרכז',
    'מסעודין אל-עזאזמה': 'מרכז',
    'מעאויה': 'מרכז',
    'מעברות': 'מרכז',
    'מעגלים': 'מרכז',
    'מעגן': 'מרכז',
    'מעגן מיכאל': 'מרכז',
    'מעוזחיים': 'מרכז',
    'מעון': 'מרכז',
    'מעונה': 'מרכז',
    'מעוף': 'מרכז',
    'מעיליא': 'צפון',
    'מעין ברוך': 'מרכז',
    'מעין צבי': 'מרכז',
    'מעלה אדומים': 'מרכז',
    'מעלה אפרים': 'מרכז',
    'מעלה גלבוע': 'מרכז',
    'מעלה גמלא': 'מרכז',
    'מעלה החמישה': 'מרכז',
    'מעלה לבונה': 'מרכז',
    'מעלה מכמש': 'מרכז',
    'מעלה עירון': 'מרכז',
    'מעלה עמוס': 'מרכז',
    'מעלה שומרון': 'מרכז',
    'מעלות-תרשיחא': 'צפון',
    'מעני אבו חא': 'מרכז',
    'מענית': 'מרכז',
    'מעסכר אל ער': 'מרכז',
    'מעש': 'מרכז',
    'מפלסים': 'מרכז',
    'מצבח': 'מרכז',
    'מצדות יהודה': 'מרכז',
    'מצובה': 'מרכז',
    'מצליח': 'מרכז',
    'מצפה': 'מרכז',
    'מצפה אבי"ב': 'מרכז',
    'מצפה אילן': 'מרכז',
    'מצפה יריחו': 'מרכז',
    'מצפה נטופה': 'מרכז',
    'מצפה רמון': 'דרום',
    'מצפה שלם': 'מרכז',
    'מצפק': 'מרכז',
    'מצר': 'מרכז',
    'מקדבה)עגילה': 'מרכז',
    'מקווה ישראל': 'מרכז',
    'מראח אל בקר': 'מרכז',
    'מראח רבאח': 'מרכז',
    'מרג נעגה': 'מרכז',
    'מרגה': 'מרכז',
    'מרגליות': 'מרכז',
    'מרדה': 'מרכז',
    'מרום גולן': 'צפון',
    'מרחב עם': 'מרכז',
    'מרחביה (מושב)': 'מרכז',
    'מרחביה (קיבוץ)': 'מרכז',
    'מרכה': 'מרכז',
    'מרכז שפירא': 'מרכז',
    'משאבישדה': 'מרכז',
    'משאיח סעדיה': 'מרכז',
    'משגב דב': 'מרכז',
    'משגב עם': 'מרכז',
    'משהד': 'מרכז',
    'משואה': 'מרכז',
    'משואות יצחק': 'מרכז',
    'משירפה': 'מרכז',
    'משכיות': 'מרכז',
    'משמר איילון': 'מרכז',
    'משמר דוד': 'מרכז',
    'משמר הירדן': 'מרכז',
    'משמר הנגב': 'מרכז',
    'משמר העמק': 'מרכז',
    'משמר השבעה': 'מרכז',
    'משמר השרון': 'מרכז',
    'משמרות': 'מרכז',
    'משמרת': 'מרכז',
    'משען': 'מרכז',
    'משרוע מוסא': 'מרכז',
    'מתמתני': 'מרכז',
    'מתן': 'מרכז',
    'מתת': 'מרכז',
    'מתתיהו': 'מרכז',
    'נאות גולן': 'צפון',
    'נאות הכיכר': 'דרום',
    'נאות מרדכי': 'מרכז',
    'נאות סמדר': 'דרום',
    'נאעורה': 'מרכז',
    'נאקורה': 'מרכז',
    'נבטים': 'דרום',
    'נבי צאלח': 'מרכז',
    'נבי צמויל': 'מרכז',
    'נביעות': 'מרכז',
    'נבק': 'מרכז',
    'נגבה': 'מרכז',
    'נגוהות': 'מרכז',
    'נגידאת': 'מרכז',
    'נגילה': 'מרכז',
    'נהורה': 'מרכז',
    'נהלל': 'מרכז',
    'נהריה': 'צפון',
    'נוב': 'מרכז',
    'נובא': 'מרכז',
    'נוגה': 'מרכז',
    'נוה': 'מרכז',
    'נוה אפרים': 'מרכז',
    'נוה דקלים': 'מרכז',
    'נווה אבות': 'מרכז',
    'נווה אור': 'מרכז',
    'נווה אטי"ב': 'מרכז',
    'נווה אילן': 'מרכז',
    'נווה איתן': 'מרכז',
    'נווה דניאל': 'מרכז',
    'נווה זוהר': 'מרכז',
    'נווה זיו': 'מרכז',
    'נווהחריף': 'מרכז',
    'נווה ים': 'מרכז',
    'נווה ימין': 'מרכז',
    'נווה ירק': 'מרכז',
    'נווה מבטח': 'מרכז',
    'נווה מיכאל': 'מרכז',
    'נווה שלום': 'מרכז',
    'נויעמה': 'מרכז',
    'נומיס': 'מרכז',
    'נועם': 'מרכז',
    'נוף איילון': 'מרכז',
    'נופים': 'מרכז',
    'נופית': 'מרכז',
    'נופך': 'מרכז',
    'נוקדים': 'מרכז',
    'נור אל שמס': 'צפון',
    'נורדיה': 'מרכז',
    'נורית': 'מרכז',
    'נזלה אל גרב': 'מרכז',
    'נזלה אל וסט': 'מרכז',
    'נזלה אל שרק': 'מרכז',
    'נזלת אבו נא': 'מרכז',
    'נזלת זיד': 'מרכז',
    'נזלת עיסא': 'מרכז',
    'נחאלין': 'מרכז',
    'נחושה': 'מרכז',
    'נחל אדורה': 'מרכז',
    'נחל אלישע': 'מרכז',
    'נחל אמתי': 'מרכז',
    'נחל בתרונות': 'מרכז',
    'נחל גבעות': 'מרכז',
    'נחל גנת': 'מרכז',
    'נחל יעלון': 'מרכז',
    'נחל מול נבו': 'מרכז',
    'נחל מרוה': 'מרכז',
    'נחל נחושתן': 'מרכז',
    'נחל נמרוד': 'מרכז',
    'נחל נצרים': 'מרכז',
    'נחל עוז': 'מרכז',
    'נחל עירית': 'מרכז',
    'נחל צורף': 'מרכז',
    'נחל צרי': 'מרכז',
    'נחל שיאון': 'מרכז',
    'נחל(חפגה)': 'מרכז',
    'נחלה': 'מרכז',
    'נחליאל': 'מרכז',
    'נחלים': 'מרכז',
    'נחלת יהודה': 'מרכז',
    'נחם': 'מרכז',
    'נחף': 'מרכז',
    'נחשולים': 'מרכז',
    'נחשון': 'מרכז',
    'נחשונים': 'מרכז',
    'נטועה': 'מרכז',
    'נטור': 'מרכז',
    'נטעים': 'מרכז',
    'נטף': 'מרכז',
    'ניין': 'מרכז',
    'ניל"י': 'מרכז',
    'ניסנית': 'מרכז',
    'ניצן': 'מרכז',
    'ניצן ב': 'מרכז',
    'ניצנה (קהילת חינוך)': 'דרום',
    'ניצני סיני': 'דרום',
    'ניצני עוז': 'מרכז',
    'ניצנים': 'מרכז',
    'ניר אליהו': 'מרכז',
    'ניר בנים': 'מרכז',
    'ניר גלים': 'מרכז',
    'ניר דוד (תל עמל)': 'מרכז',
    'ניר ח"ן': 'מרכז',
    'ניר יפה': 'מרכז',
    'ניר יצחק': 'מרכז',
    'ניר ישראל': 'מרכז',
    'ניר משה': 'מרכז',
    'ניר עוז': 'מרכז',
    'ניר עם': 'מרכז',
    'ניר עציון': 'מרכז',
    'ניר עקיבא': 'מרכז',
    'ניר צבי': 'מרכז',
    'נירים': 'מרכז',
    'נירית': 'מרכז',
    'נירן': 'מרכז',
    'נמל תעופה בן גוריון': 'מרכז',
    'נס הרים': 'מרכז',
    'נס עמים': 'מרכז',
    'נס ציונה': 'מרכז',
    'נעורים': 'מרכז',
    'נעלה': 'מרכז',
    'נעלין': 'מרכז',
    'נעמ"ה': 'מרכז',
    'נען': 'מרכז',
    'נצאצרה (שבט)': 'מרכז',
    'נצאריה': 'מרכז',
    'נציראת': 'מרכז',
    'נצף גביל': 'מרכז',
    'נצר חזני': 'מרכז',
    'נצר חזני *': 'מרכז',
    'נצר סרני': 'מרכז',
    'נצרת': 'מרכז',
    'נצרת עילית': 'צפון',
    'נקא שעבאני': 'מרכז',
    'נשר': 'מרכז',
    'נתיב הגדוד': 'מרכז',
    'נתיב הל"ה': 'מרכז',
    'נתיב העשרה': 'מרכז',
    'נתיב השיירה': 'מרכז',
    'נתיבות': 'מרכז',
    'נתניה': 'מרכז',
    'סאגור': 'מרכז',
    'סאויה': 'מרכז',
    'סאלם': 'מרכז',
    'סאלם': 'מרכז',
    'סאסא': 'מרכז',
    'סביון': 'מרכז',
    'סבסטיה': 'מרכז',
    'סגולה': 'מרכז',
    'סדום': 'דרום',
    'סואחרה': 'מרכז',
    'סואעד (חמרייה)': 'מרכז',
    'סואעד (כמאנה) )שבט)': 'מרכז',
    'סולם': 'מרכז',
    'סוסיה': 'דרום',
    'סופה': 'מרכז',
    'סחנין': 'צפון',
    'סייד (שבט)': 'מרכז',
    'סייפה': 'מרכז',
    'סילת אל דאה': 'מרכז',
    'סילת אל חאר': 'מרכז',
    'סיריס': 'מרכז',
    'סלואד': 'מרכז',
    'סלמאנה': 'מרכז',
    'סלמה': 'מרכז',
    'סלעית': 'מרכז',
    'סלפית': 'מרכז',
    'סמוע': 'מרכז',
    'סמר': 'מרכז',
    'סנטה קטרינה': 'דרום',
    'סניריה': 'מרכז',
    'עטאוונה (שבט)': 'מרכז',
    'עטאטרה': 'מרכז',
    'עטארה ג\'נין': 'צפון',
    'עטארה רמאלל': 'מרכז',
    'עטרת': 'מרכז',
    'עידן': 'מרכז',
    'עיזריה': 'מרכז',
    'עיילבון': 'מרכז',
    'עיינות': 'מרכז',
    'עילוט': 'צפון',
    'עין א סהלה': 'מרכז',
    'עין איוב': 'מרכז',
    'עין איילה': 'מרכז',
    'עין אל-אסד': 'צפון',
    'עין אל אחדר': 'מרכז',
    'עין אל בידא': 'מרכז',
    'עין אל שלטא': 'מרכז',
    'עין גב': 'צפון',
    'עין גדי': 'דרום',
    'עין דור': 'מרכז',
    'עין דרויש': 'מרכז',
    'עין הבשור': 'דרום',
    'עין הוד': 'מרכז',
    'עין החורש': 'מרכז',
    'עין המפרץ': 'צפון',
    'עין הנצי"ב': 'מרכז',
    'עין העמק': 'מרכז',
    'עין השופט': 'מרכז',
    'עין השלושה': 'מרכז',
    'עין ורד': 'מרכז',
    'עין זיוון': 'צפון',
    'עין חוד': 'מרכז',
    'עין חצבה': 'מרכז',
    'עין חרוד (איחוד)': 'מרכז',
    'עין חרוד (מאוחד)': 'מרכז',
    'עין יברוד': 'מרכז',
    'עין יהב': 'דרום',
    'עין יעקב': 'מרכז',
    'עין כרם-בי"ס חקלאי': 'מרכז',
    'עין כרמל': 'מרכז',
    'עין מאהל': 'צפון',
    'עין נקובא': 'מרכז',
    'עין סיניא': 'מרכז',
    'עין עירון': 'מרכז',
    'עין עריכ': 'מרכז',
    'עין צדר': 'מרכז',
    'עין צורים': 'מרכז',
    'עין קידכאת': 'מרכז',
    'עין קיניא': 'מרכז',
    'עין קנייא': 'מרכז',
    'עין ראפה': 'מרכז',
    'עין שמר': 'מרכז',
    'עין שריד': 'מרכז',
    'עין תמר': 'מרכז',
    'עינאבוס': 'מרכז',
    'עינת': 'מרכז',
    'עיר אובות': 'דרום',
    'עכו': 'צפון',
    'עלאר': 'מרכז',
    'עלומים': 'מרכז',
    'עלי': 'מרכז',
    'עלי זהב': 'מרכז',
    'עלמה': 'מרכז',
    'עלמון': 'מרכז',
    'עלקה': 'מרכז',
    'עמוקה': 'מרכז',
    'עמור': 'מרכז',
    'עמוריה': 'מרכז',
    'עמינדב': 'מרכז',
    'עמיעד': 'מרכז',
    'עמיעוז': 'מרכז',
    'עמיקם': 'מרכז',
    'עמיר': 'מרכז',
    'עמנואל': 'מרכז',
    'עמק חפר': 'מרכז',
    'עמק האלה': 'מרכז',
    'עמקה': 'מרכז',
    'ענאב אל צגי': 'מרכז',
    'ענאתא': 'מרכז',
    'ענב': 'מרכז',
    'ענבתא': 'מרכז',
    'ענזה': 'מרכז',
    'עסאכרה': 'מרכז',
    'עסכר': 'מרכז',
    'עספיא': 'צפון',
    'עפולה': 'מרכז',
    'עץ אפרים': 'מרכז',
    'עצירה אל קב': 'מרכז',
    'עצירה אלשמא': 'מרכז',
    'עצמון שגב': 'מרכז',
    'עקאבה': 'מרכז',
    'עקבת גבר': 'מרכז',
    'עקרבה': 'מרכז',
    'עראבה': 'צפון',
    'עראמשה': 'מרכז',
    'עראמשה שבט': 'מרכז',
    'עראנה': 'מרכז',
    'עראקבורין': 'מרכז',
    'ערב אבו ציב': 'מרכז',
    'ערב אל חסינ': 'מרכז',
    'ערב אל נעים': 'מרכז',
    'ערבונה': 'מרכז',
    'ערד': 'דרום',
    'ערוגות': 'מרכז',
    'ערערה': 'מרכז',
    'ערערה-בנגב': 'דרום',
    'ערקה': 'מרכז',
    'עשרת': 'מרכז',
    'עתיל': 'מרכז',
    'עתלית': 'מרכז',
    'עתניאל': 'מרכז',
    'פארן': 'דרום',
    'פאת שדה': 'מרכז',
    'פדואל': 'מרכז',
    'פדויים': 'מרכז',
    'פדיה': 'מרכז',
    'פוגיה': 'מרכז',
    'פוור': 'מרכז',
    'פוריה-כפר עבודה': 'צפון',
    'פוריה-נווה עובד': 'צפון',
    'פוריה עילית': 'צפון',
    'פוריידיס': 'מרכז',
    'פורת': 'מרכז',
    'פחמה': 'מרכז',
    'פטיש': 'מרכז',
    'פיראן': 'מרכז',
    'פלאמה': 'מרכז',
    'פלך': 'מרכז',
    'פלמחים': 'מרכז',
    'פנדק': 'מרכז',
    'פנדקומיה': 'מרכז',
    'פני חבר': 'מרכז',
    'פסגות': 'מרכז',
    'פסוטה': 'מרכז',
    'פעמי תש"ז': 'מרכז',
    'פצאיל': 'מרכז',
    'פצאל': 'מרכז',
    'פקועה': 'מרכז',
    'פקיעין (בוקייעה)': 'צפון',
    'פקיעין חדשה': 'צפון',
    'פרדס חנה-כרכור': 'מרכז',
    'פרדסיה': 'מרכז',
    'פרוד': 'מרכז',
    'פרוש בית דג': 'מרכז',
    'פרזון': 'מרכז',
    'פרחה': 'מרכז',
    'פרי גן': 'מרכז',
    'פרנגה': 'מרכז',
    'פרעה אל גיפ': 'מרכז',
    'פרעון': 'מרכז',
    'פרעתה': 'מרכז',
    'פתח תקווה': 'מרכז',
    'פתחיה': 'מרכז',
    'צאלים': 'מרכז',
    'צאנור': 'מרכז',
    'צביה': 'מרכז',
    'צבעון': 'מרכז',
    'צובה': 'מרכז',
    'צוחר': 'מרכז',
    'צופיה': 'מרכז',
    'צופים': 'מרכז',
    'צופית': 'מרכז',
    'צופר': 'מרכז',
    'צוקי ים': 'מרכז',
    'צוקים': 'מרכז',
    'צור הדסה': 'מרכז',
    'צור יגאל': 'מרכז',
    'צור יצחק': 'מרכז',
    'צור משה': 'מרכז',
    'צור נתן': 'מרכז',
    'צוריאל': 'מרכז',
    'צוריף': 'מרכז',
    'צורית': 'מרכז',
    'צורן': 'מרכז',
    'צידא': 'מרכז',
    'ציפורי': 'מרכז',
    'ציר': 'מרכז',
    'צלפון': 'מרכז',
    'צנדלה': 'מרכז',
    'צפא': 'מרכז',
    'צפריה': 'מרכז',
    'צפרירים': 'מרכז',
    'צפת': 'צפון',
    'צרה': 'מרכז',
    'צרופה': 'מרכז',
    'צרטא': 'מרכז',
    'צרעה': 'מרכז',
    'ק.מ.45': 'מרכז',
    'קבאטיה': 'מרכז',
    'קבה': 'מרכז',
    'קבועה (שבט)': 'מרכז',
    'קבוצת יבנה': 'מרכז',
    'קביא': 'מרכז',
    'קביבה': 'מרכז',
    'קבלאן': 'מרכז',
    'קבר עמיר': 'מרכז',
    'קדומים': 'מרכז',
    'קדימה-צורן': 'מרכז',
    'קדמה': 'מרכז',
    'קדמת צבי': 'מרכז',
    'קדר': 'מרכז',
    'קדרון': 'מרכז',
    'קדרים': 'מרכז',
    'קודייראת א-': 'מרכז',
    'צאנע(שבט)': 'מרכז',
    'קוואעין (שבט)': 'מרכז',
    'קוממיות': 'מרכז',
    'קוצימה': 'מרכז',
    'קוצין': 'מרכז',
    'קורנית': 'מרכז',
    'קטורה': 'דרום',
    'קטיף': 'דרום',
    'קטנה': 'מרכז',
    'קיסריה': 'מרכז',
    'קירה': 'מרכז',
    'קלחים': 'מרכז',
    'קליה': 'מרכז',
    'קלנדיה': 'מרכז',
    'קלנסווה': 'מרכז',
    'קלע': 'מרכז',
    'קלקיליה': 'מרכז',
    'קפין': 'מרכז',
    'קציר': 'מרכז',
    'קצר א-סר': 'מרכז',
    'קצרה': 'מרכז',
    'קצרין': 'מרכז',
    'קראות בני ז': 'מרכז',
    'קראות בני ח': 'מרכז',
    'קרארה': 'מרכז',
    'קריות': 'מרכז',
    'קרית אונו': 'מרכז',
    'קרית ארבע': 'דרום',
    'קרית אתא': 'צפון',
    'קרית ביאליק': 'צפון',
    'קרית גת': 'דרום',
    'קרית חיים': 'צפון',
    'קרית טבעון': 'מרכז',
    'קרית ים': 'צפון',
    'קרית יערים': 'מרכז',
    'קרית יערים(מוסד)': 'מרכז',
    'קרית מוצקין': 'צפון',
    'קרית מלאכי': 'דרום',
    'קרית נטפים': 'מרכז',
    'קרית ענבים': 'מרכז',
    'קרית עקרון': 'מרכז',
    'קרית שלמה': 'מרכז',
    'קריתשמונה': 'צפון',
    'קרני שומרון': 'מרכז',
    'קשת': 'מרכז',
    'ראבא': 'מרכז',
    'ראבוד': 'מרכז',
    'ראם': 'מרכז',
    'ראמה': 'צפון',
    'ראמין': 'מרכז',
    'ראס': 'מרכז',
    'ראס אל-עין': 'מרכז',
    'ראס אל נקב': 'מרכז',
    'ראס כרכר': 'מרכז',
    'ראס נצראני': 'מרכז',
    'ראס סאלם': 'מרכז',
    'ראס סניסל': 'מרכז',
    'ראס עלי': 'מרכז',
    'ראפאת חברון': 'מרכז',
    'ראפאת טול כ': 'מרכז',
    'ראפאת רמאלל': 'מרכז',
    'ראש העין': 'מרכז',
    'ראש פינה': 'צפון',
    'ראש צורים': 'מרכז',
    'ראשון לציון': 'מרכז',
    'רבבה': 'מרכז',
    'רבדים': 'מרכז',
    'רביבים': 'מרכז',
    'רביד': 'מרכז',
    'רבעהכולל ב': 'מרכז',
    'רגבה': 'מרכז',
    'רגבים': 'מרכז',
    'רהט': 'דרום',
    'רוגיב': 'מרכז',
    'רווחה': 'מרכז',
    'רוויה': 'מרכז',
    'רוח מדבר': 'מרכז',
    'רוחמה': 'מרכז',
    'רומאנה': 'מרכז',
    'רומת הייב': 'מרכז',
    'רועי': 'מרכז',
    'רותם': 'מרכז',
    'רחוב': 'מרכז',
    'רחובות': 'מרכז',
    'רחימה': 'מרכז',
    'ריחאניה': 'מרכז',
    'ריחיה': 'מרכז',
    'ריחן': 'מרכז',
    'ריינה': 'מרכז',
    'רימונים': 'מרכז',
    'רינתיה': 'מרכז',
    'ריסאן': 'מרכז',
    'רכום': 'מרכז',
    'רכסים': 'מרכז',
    'רם-און': 'מרכז',
    'רמאללה': 'מרכז',
    'רמאנה': 'מרכז',
    'רמון': 'מרכז',
    'רמות': 'מרכז',
    'רמות השבים': 'מרכז',
    'רמות מאיר': 'מרכז',
    'רמות מנשה': 'מרכז',
    'רמותנפתלי': 'מרכז',
    'רמלה': 'מרכז',
    'רמסה': 'מרכז',
    'רמת אפעל': 'מרכז',
    'רמת גן': 'מרכז',
    'רמת דוד': 'מרכז',
    'רמת הכובש': 'מרכז',
    'רמת השופט': 'מרכז',
    'רמת השרון': 'מרכז',
    'רמת חובב': 'מרכז',
    'רמת יוחנן': 'מרכז',
    'רמת ישי': 'מרכז',
    'רמת מגשימים': 'מרכז',
    'רמת פנקס': 'מרכז',
    'רמת צבי': 'מרכז',
    'רמת רזיאל': 'מרכז',
    'רמת רחל': 'מרכז',
    'רנן': 'מרכז',
    'רנתיס': 'מרכז',
    'רעים': 'מרכז',
    'רעננה': 'מרכז',
    'רפידה': 'מרכז',
    'רפידיה': 'מרכז',
    'רפיח': 'דרום',
    'רפיח העיר': 'דרום',
    'רפיח ים': 'דרום',
    'רקפת': 'מרכז',
    'רשפון': 'מרכז',
    'רשפים': 'מרכז',
    'רתמים': 'מרכז',
    'שא-נור': 'צפון',
    'שאטי': 'מרכז',
    'שאר ישוב': 'מרכז',
    'שבי ציון': 'מרכז',
    'שבי שומרון': 'מרכז',
    'שבלי-אום אל-גנם': 'מרכז',
    'שבע בארות': 'מרכז',
    'שבתין': 'מרכז',
    'שגב-שלום': 'מרכז',
    'שדה אילן': 'מרכז',
    'שדה אליהו': 'מרכז',
    'שדה אליעזר': 'מרכז',
    'שדה בוקר': 'דרום',
    'שדה דוד': 'מרכז',
    'שדה ורבורג': 'מרכז',
    'שדה יואב': 'דרום',
    'שדה יעקב': 'מרכז',
    'שדה יצחק': 'מרכז',
    'שדה משה': 'מרכז',
    'שדה נחום': 'מרכז',
    'שדה נחמיה': 'מרכז',
    'שדה ניצן': 'דרום',
    'שדה עוזיהו': 'מרכז',
    'שדה צבי': 'מרכז',
    'שדות ים': 'מרכז',
    'שדות מיכה': 'מרכז',
    'שדי אברהם': 'מרכז',
    'שדי חמד': 'מרכז',
    'שדי תרומות': 'מרכז',
    'שדמה': 'מרכז',
    'שדמות דבורה': 'מרכז',
    'שדמות מחולה': 'מרכז',
    'שדרות': 'דרום',
    'שואבה': 'מרכז',
    'שובה': 'מרכז',
    'שובל': 'מרכז',
    'שוהם': 'מרכז',
    'שויכה': 'מרכז',
    'שוכת א צופי': 'מרכז',
    'שומרה': 'מרכז',
    'שומריה': 'מרכז',
    'שופה': 'מרכז',
    'שוקדה': 'מרכז',
    'שורש': 'מרכז',
    'שורשים': 'מרכז',
    'שושנת העמקים': 'מרכז',
    'שזור': 'צפון',
    'שחר': 'מרכז',
    'שחרות': 'מרכז',
    'שיבולים': 'מרכז',
    'שיוח': 'מרכז',
    'שיח אחמד': 'מרכז',
    'שיח אחמד אל': 'מרכז',
    'שיח בריכ': 'מרכז',
    'שיחזויד': 'מרכז',
    'שיח חשש': 'מרכז',
    'שיח סלימאן': 'מרכז',
    'שיח סעיד': 'מרכז',
    'שיח עגלין': 'מרכז',
    'שיח עוד': 'מרכז',
    'שיטים': 'מרכז',
    'שייח דנון': 'מרכז',
    'שילה': 'מרכז',
    'שילת': 'מרכז',
    'שכם': 'מרכז',
    'שכניה': 'מרכז',
    'שלאק וג\'ורה': 'מרכז',
    'שלווה': 'מרכז',
    'שלווה במדבר': 'מרכז',
    'שלוחות': 'מרכז',
    'שלומי': 'מרכז',
    'שלומית': 'מרכז',
    'שליו': 'מרכז',
    'שמיר': 'מרכז',
    'שמעה': 'מרכז',
    'שמרת': 'מרכז',
    'שמשית': 'מרכז',
    'שני': 'מרכז',
    'שניר': 'מרכז',
    'שעב': 'מרכז',
    'שעורים': 'מרכז',
    'שעל': 'מרכז',
    'שעלבים': 'מרכז',
    'שער אפרים': 'מרכז',
    'שער הגולן': 'צפון',
    'שער העמקים': 'מרכז',
    'שער מנשה': 'מרכז',
    'שערי אברהם': 'מרכז',
    'שערי תקווה': 'מרכז',
    'שפיים': 'מרכז',
    'שפיר': 'מרכז',
    'שפר': 'מרכז',
    'שפרעם': 'מרכז',
    'שקבה': 'מרכז',
    'שקד': 'מרכז',
    'שקף': 'מרכז',
    'שרונה': 'מרכז',
    'שריגים (לי-און)': 'מרכז',
    'שריד': 'מרכז',
    'שרשרת': 'מרכז',
    'שתולה': 'מרכז',
    'שתולים': 'מרכז',
    'תאשור': 'מרכז',
    'תדהר': 'מרכז',
    'תובל': 'מרכז',
    'תומר': 'מרכז',
    'תושיה': 'מרכז',
    'תיאסיר': 'מרכז',
    'תימורים': 'מרכז',
    'תירוש': 'מרכז',
    'תל': 'מרכז',
    'תל א שמסיה': 'מרכז',
    'תל אבו שנאר': 'מרכז',
    'תל אביב-יפו': 'מרכז',
    'תל אל אחמר': 'מרכז',
    'תל אל בידא': 'מרכז',
    'תליוסף': 'מרכז',
    'תל יצחק': 'מרכז',
    'תל מונד': 'מרכז',
    'תל עדשים': 'מרכז',
    'תל קציר': 'מרכז',
    'תל שבע': 'מרכז',
    'תל תאומים': 'מרכז',
    'תלם': 'מרכז',
    'תלמי אליהו': 'מרכז',
    'תלמי אלעזר': 'מרכז',
    'תלמי ביל"ו': 'מרכז',
    'תלמי יוסף': 'מרכז',
    'תלמי יחיאל': 'מרכז',
    'תלמי יפה': 'מרכז',
    'תלמים': 'מרכז',
    'תלפית ג\'נין': 'מרכז',
    'תלפית שכם': 'מרכז',
    'תמד': 'מרכז',
    'תמרת': 'מרכז',
    'תנובות': 'מרכז',
    'תעוז': 'מרכז',
    'תענך': 'מרכז',
    'תפוח': 'מרכז',
    'תפרח': 'מרכז',
    'תקומה': 'מרכז',
    'תקוע': 'מרכז',
    'תראבין א-צאנע (שבט)': 'מרכז',
    'תראבין א-צאנע(ישוב)': 'מרכז',
    'תרום': 'מרכז',
    'תרמוסעיא': 'מרכז',
    'תרפת אל קדר': 'מרכז',
    'תרקומיא': 'מרכז',
    // Continue with the next community names in the list...
};

// The rest of the communities can be added similarly in their appropriate regions.

export default communityToRegion;
