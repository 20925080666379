import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { doSignOut } from "../../firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
  
      // Check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);
  
      // Check if the auth provider is Google
      const isGoogle = user.providerData.some(
        (provider) => provider.providerId === "google.com"
      );
      setIsGoogleUser(isGoogle);
  
      // Fetch user role from Firestore
      try {
        let userRole = 'user'; // Default role if not found in any collection
  
        // Check in 'users' collection
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          userRole = userDoc.data().role;
        } else {
          // Check in 'coordinators' collection
          const coordinatorDocRef = doc(db, 'coordinators', user.uid);
          const coordinatorDoc = await getDoc(coordinatorDocRef);
          if (coordinatorDoc.exists()) {
            userRole = 'coordinator';
          } else {
            // Check in 'businesses' collection
            const businessDocRef = doc(db, 'businesses', user.uid);
            const businessDoc = await getDoc(businessDocRef);
            if (businessDoc.exists()) {
              userRole = 'business';
            }
          }
        }
  
        setUserRole(userRole);
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole('user'); // Default to 'user' in case of error
      }
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setUserRole(null);
    }
    setLoading(false);
  }
  

  const signOut = () => {
    return doSignOut().then(() => {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setUserRole(null);
    }).catch((error) => {
      console.error("Logout failed: ", error);
      throw error; // Rethrow after logging
    });
  };

  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    userRole,
    signOut,
    setCurrentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}